import React, { Component, Fragment, useEffect, useState } from "react";
import Navbar from "../../UI/Navbar";

import "../../../App.css";
import { ChevronRight } from "react-bootstrap-icons";

function LeaUseOfForce() {
  const [pressData, setPressData] = useState([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/OIS Reports.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPressData(data);
      });
  }, []);

  const pressReleasesByYear = {};

  // Group press releases by year
  pressData.forEach((release) => {
    const year = new Date(release.date).getFullYear();
    if (!pressReleasesByYear[year]) {
      pressReleasesByYear[year] = [];
    }
    pressReleasesByYear[year].push(release);
  });

  // Filter press releases by OIS in title or link
  // const filteredPressReleases = {};
  // Object.entries(pressReleasesByYear).forEach(([year, releases]) => {
  //     let releasesForYear = releases.filter((release) =>
  //     release.title.includes('OIS')
  //     );

  //     if (releasesForYear.length === 0) {

  //     }
  //     else {
  //         filteredPressReleases[year] = releasesForYear;
  //     }

  // });

  return (
    <Fragment>
      <Navbar></Navbar>
      <div className="container">
        <div className="content-wrapper font-display text-base">
          <div className="main-content">
            <div
              className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max"
            >
              Law Enforcement Use of Force
            </div>
            <p className="mt-4">
              The Santa Barbara District Attorney’s Office independently reviews
              officer-involved shooting incidents resulting in injury or death,
              as well as all other uses of force resulting in the death of
              individuals while in custody or control of law enforcement
              officers. This review addresses whether the officers involved
              committed any violation of state criminal law.
            </p>
            <p className="mt-4">
              Our role is to ensure that the investigation and review of these
              incidents is conducted in a fair and objective manner that will
              serve the interests of justice, the community, the involved
              officers, those persons injured, and the families of those
              affected.
            </p>
            <p className="mt-4">
              At the conclusion of the investigation and review, and if there is
              no pending criminal case associated with the incident, the
              District Attorney’s Office will issue a written report, detailing
              factual and legal findings. This report will be made available to
              the public.
            </p>
            <p className="mt-4 mb-4">
              For additional information, please refer to the Santa Barbara
              County District Attorney’s Protocol for Officer-Involved Incident
              Response Program, linked{" "}
              <a
                className="font-bold"
                style={{ color: "blue" }}
                href="/documents/DA_OIS_Protocol.pdf"
              >
                HERE
              </a>
            </p>

            <div
              className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b-2 border-mustard w-max mb-4 mt-5"
            >
              Officer Involved Incident Reports
            </div>
            {Object.entries(pressReleasesByYear)
              .reverse()
              .map(([year, releases]) => (
                <div key={year}>
                  <div
                    className="
                                        align-self-center
                                        font-bold text-xl font-display 
                                        border-b  w-max mb-4 mt-4"
                  >
                    {year}
                  </div>
                  {releases.map((release) => (
                    <a
                      key={release.link}
                      href={release.link}
                      className="flex items-baseline mb-1 text-base"
                    >
                      <span className="mr-1">{release.date + ": "}</span>{" "}
                      {release.title}
                    </a>
                  ))}
                </div>
              ))}
          </div>
          <div className="secondary-content">
            <div
              className="
                                    align-self-center
                                    font-bold text-xl font-display 
                                    border-b-2 border-mustard w-max"
            >
              Contact Information
            </div>
            <p>&nbsp;</p>
            <p>
              <span class="bldTxt">
                <a href="/contact">
                  <strong>Santa Barbara</strong>
                </a>
              </span>
            </p>
            <p>
              1112 Santa Barbara Street
              <br />
              Santa Barbara, CA 93101
              <br />
              Tel: (805) 568-2300
            </p>
            <p>&nbsp;</p>
            <p>
              <a href="/contact">
                <strong>Santa Maria</strong>
              </a>
              <br />
            </p>
            <p>
              312-D East Cook Street
              <br />
              Santa Maria, CA 93454
              <br />
              Tel: (805) 346-7540
            </p>
            <p>
              <br />
              <a href="/contact">
                <strong>Lompoc</strong>
              </a>
              <br />
            </p>
            <p>
              115 E. Civic Center Plaza
              <br />
              Lompoc, CA 93436
              <br />
              Tel: (805) 737-7760
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LeaUseOfForce;
